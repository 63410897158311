<template>
  <v-form
    ref="form"
    v-model="valid"
    class="rcc-legal-entities-form"
    @submit.prevent="onSubmitForm"
  >
    <div class="rcc-legal-entities-form__form-body">
      <rcc-text-input
        label="Название"
        width="40%"
        v-model="form.name"
        :rules="[value => isRequiredField({ value, field: 'Название' })]"
      />

      <rcc-select label="Сеть" width="40%" :items="networksOptions" v-model="form.network_id" />
      <rcc-text-input label="Торговая марка" width="40%" v-model="form.trademark" />
    </div>

    <rcc-footer-buttons :is-submit="isSubmit" @cancel-click="$router.push({ name: 'legal-entities' })"/>
  </v-form>
</template>

<script>
import { getNetworksList } from '@/api/trading-networks'
import LegalEntitiesApi from '@/api/legal-entities'
import { getOptions } from '@/utils/get-options'
import Page from '@/mixins/page'
import Form from '@/mixins/form'

import RccFooterButtons from 'Components/layouts/rcc-footer-buttons'
import RccTextInput from 'Components/controls/rcc-text-input'
import RccSelect from 'Components/controls/rcc-select.vue'

export default {
  components: {
    RccTextInput,
    RccFooterButtons,
    RccSelect
  },

  mixins: [Page, Form, LegalEntitiesApi],

  data() {
    return {
      networksOptions: [],

      form: {
        network_id: null,
        trademark: null,
        name: null
      }
    }
  },

  computed: {
    selects() {
      return [
        { method: getNetworksList, target: 'networksOptions', errorMessage: 'Не удалось загрузить данные для Сетей' }
      ]
    }
  },

  created() {
    getOptions.bind(this)(this.selects)
  }
}
</script>

<style lang="scss" scoped>
.rcc-legal-entities-form {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__form-body {
    flex-grow: 1;
    padding: $base-form-padding;
  }

  &__booking-section {
    display: flex;
    gap: 20px;
  }

  &__number-input {
    flex: none;
    width: 250px;
  }

  &__form-section {
    margin: 10px 0;
  }
}
</style>
